import React from 'react';

export default function Instagram() {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.3435 7.37587C27.0216 5.63551 26.1836 4.0337 24.9395 2.78085C23.6955 1.52799 22.1036 0.682668 20.373 0.355885H20.3476C16.086 -0.118628 11.7853 -0.118628 7.5238 0.355885C5.78959 0.674456 4.19329 1.51721 2.94785 2.7715C1.7024 4.02579 0.867079 5.63195 0.553333 7.37587H0.527915C0.0733773 11.6745 0.0733773 16.0095 0.527915 20.3081L0.553333 20.3336C0.867079 22.0775 1.7024 23.6837 2.94785 24.938C4.19329 26.1923 5.78959 27.035 7.5238 27.3536C11.7853 27.8298 16.0861 27.8298 20.3476 27.3536H20.373C22.1036 27.0268 23.6955 26.1815 24.9395 24.9286C26.1836 23.6758 27.0216 22.074 27.3435 20.3336V20.3081C27.798 16.0095 27.798 11.6745 27.3435 7.37587ZM25.1216 20.0529C24.8722 21.3004 24.2624 22.4462 23.3686 23.3472C22.4747 24.2483 21.3365 24.8646 20.0962 25.1187C16.0016 25.5953 11.866 25.5953 7.7714 25.1187C6.531 24.8647 5.39265 24.2486 4.49879 23.3475C3.60493 22.4465 2.99528 21.3005 2.74605 20.0529C2.29152 15.9245 2.29152 11.7582 2.74605 7.62984C2.99543 6.38228 3.60519 5.23652 4.49902 4.33548C5.39286 3.43444 6.53107 2.81812 7.7714 2.56397C11.8679 2.12314 15.9996 2.12314 20.0962 2.56397C21.3364 2.81828 22.4746 3.43447 23.3684 4.33548C24.2622 5.23649 24.872 6.38235 25.1216 7.62984C25.5761 11.7582 25.5761 15.9245 25.1216 20.0529Z"
        fill="#F42E3C"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.9326 7.19189C12.6363 7.1919 11.3691 7.57854 10.2915 8.30279C9.2138 9.02704 8.37408 10.0564 7.87859 11.2606C7.38309 12.4648 7.2541 13.7897 7.50793 15.0676C7.76175 16.3455 8.38697 17.519 9.30449 18.4395C10.222 19.3601 11.3906 19.9864 12.6623 20.2391C13.934 20.4918 15.2517 20.3593 16.4486 19.8589C17.6455 19.3585 18.6678 18.5125 19.3862 17.4277C20.1045 16.343 20.4866 15.0685 20.4841 13.7653C20.4758 12.0224 19.7825 10.3533 18.5553 9.12202C17.3281 7.89074 15.6665 7.19693 13.9326 7.19189ZM13.9326 18.1214C13.0799 18.1196 12.2468 17.8641 11.5384 17.3869C10.8301 16.9097 10.2781 16.2324 9.95227 15.4403C9.62642 14.6481 9.54123 13.7766 9.70746 12.9358C9.8737 12.0951 10.284 11.3226 10.8864 10.7159C11.4888 10.1092 12.2565 9.6957 13.0925 9.52711C13.9286 9.35852 14.7956 9.44244 15.5841 9.76861C16.3727 10.0948 17.0476 10.6484 17.5235 11.3597C17.9994 12.071 18.2551 12.9081 18.2584 13.7653C18.2557 14.9185 17.7994 16.0239 16.989 16.84C16.1785 17.6562 15.0798 18.1166 13.9326 18.1214Z"
        fill="#F42E3C"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.8762 6.80928C19.8906 6.53966 19.9833 6.28018 20.1429 6.06297C20.3024 5.84576 20.5218 5.68033 20.7739 5.58714C21.026 5.49395 21.2998 5.47696 21.5613 5.53853C21.8228 5.60009 22.0607 5.73732 22.2454 5.93334C22.4301 6.12935 22.5536 6.37548 22.6006 6.64132C22.6477 6.90716 22.6161 7.18106 22.51 7.42908C22.4038 7.67709 22.2276 7.8883 22.0032 8.03672C21.7788 8.18514 21.5161 8.26431 21.2475 8.26419C20.8738 8.25384 20.5194 8.09485 20.2623 7.82202C20.0052 7.54919 19.8663 7.18497 19.8762 6.80928Z"
        fill="#F42E3C"
      />
    </svg>
  );
}
