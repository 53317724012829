import { Footer } from '../../layouts';
import heroImage from '../../assets/images/Summer_Gana_Bankaya_web.webp';
import heroImageMobile from '../../assets/images/Summer_Gana_Bankaya_mobile.webp';

import './styles.sass';

export default function Hero() {
  const image = window.innerWidth > 996 ? heroImage : heroImageMobile;
  return (
    <>
      <div className="hero-container">
        <a
          className="hero-img-container"
          title="Gana con Bankaya"
          href="https://app.adjust.com/1bf8ren8"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={image} alt="gana con bankaya" />
        </a>
        <Footer />
      </div>
    </>
  );
}
