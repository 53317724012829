import './app/assets/styles/index.sass';

import Adjust from '@adjustcom/adjust-web-sdk';
import Hero from './app/pages/Hero';

Adjust.initSdk({
  appToken: 'APP_TOKEN',
  environment: 'sandbox',
});

function App() {
  return (
    <Hero />
  );
}
export default App;
