export default function BankayaLogo() {
  return (
    <svg
      width="212"
      height="52"
      viewBox="0 0 212 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3481 13.3318C11.1897 13.3318 9.07059 14.9627 8.00925 16.5759V3.61719C0.474832 3.61719 0.909474 8.54186 0.909474 8.54186V30.8039C0.909474 33.5268 0.909517 36.2887 0.862427 39.0117H7.65065C7.81979 38.2292 7.91078 37.4324 7.92232 36.6327C9.1684 38.4586 10.8491 39.5931 14.6598 39.5931C22.0312 39.5931 25.8093 33.2113 25.8093 26.014C25.8093 18.9727 22.3283 13.3318 15.3481 13.3318ZM13.1023 34.0693C8.96556 34.0693 7.66878 30.8252 7.66878 26.5954C7.66878 21.4829 9.11767 18.8982 13.1638 18.8982C16.9962 18.8982 18.5973 21.965 18.5973 26.1487C18.6045 30.8926 16.7101 34.0693 13.1023 34.0693Z"
        fill="#2364E6"
      />
      <path
        d="M51.8203 21.9652C51.8203 15.991 48.0821 13.3638 41.2141 13.3213H41.0765C33.3864 13.3213 30.5972 16.9129 30.2096 21.3483H36.9615C37.2876 19.5294 37.9468 18.2814 40.9461 18.2814C44.2388 18.2814 44.8944 20.0364 44.8944 21.8269V23.3833H41.2359C33.716 23.3833 29.0251 26.0212 29.0251 31.74C29.0251 35.64 31.6332 39.611 37.8165 39.611C42.3516 39.611 44.0939 37.9871 45.0248 36.576C45.0428 37.4053 45.1644 38.2293 45.3871 39.0295H52.1717C52.0231 38.4233 51.8094 34.9345 51.8094 32.5448L51.8203 21.9652ZM44.9016 28.2336C44.9016 31.5521 44.3474 34.6154 39.8304 34.6154C37.2948 34.6154 36.1393 33.0873 36.1393 31.2685C36.1393 28.8505 37.7258 27.5706 42.0038 27.5706H44.9016V28.2336Z"
        fill="#2364E6"
      />
      <path
        d="M72.8309 13.3318C68.3356 13.3318 66.2637 15.5335 65.3907 17.1113C65.3596 16.0435 65.2676 14.9782 65.1154 13.9203H58.233C58.2837 15.7746 58.2837 18.1111 58.2837 20.2419V39.0329H65.3834V25.046C65.3834 20.6142 67.1077 18.9727 70.1251 18.9727C73.2584 18.9727 74.2183 21.0184 74.2183 23.837V39.0329H81.318V23.0783C81.3071 16.739 77.9637 13.3318 72.8309 13.3318Z"
        fill="#2364E6"
      />
      <path
        d="M111.672 13.9133H103.533C99.994 18.1005 96.5926 21.9969 94.7053 24.5036V3.61719H87.6057V39.0117H94.7053V30.9563L97.9872 27.847L104.475 39.0117H112.52L102.74 23.3903L111.672 13.9133Z"
        fill="#2364E6"
      />
      <path
        d="M137.011 32.5271V21.9652C137.011 15.991 133.273 13.3638 126.405 13.3213H126.268C118.574 13.3213 115.788 16.9129 115.401 21.3483H122.153C122.479 19.5294 123.134 18.2814 126.137 18.2814C129.43 18.2814 130.085 20.0364 130.085 21.8269V23.3833H126.441C118.921 23.3833 114.231 26.0212 114.231 31.74C114.231 35.64 116.839 39.611 123.022 39.611C127.557 39.611 129.299 37.9871 130.23 36.576C130.248 37.4053 130.37 38.2293 130.593 39.0295H137.374C137.207 38.4055 137.011 34.9168 137.011 32.5271ZM130.089 28.2442C130.089 31.5627 129.535 34.626 125.018 34.626C122.464 34.626 121.327 33.098 121.327 31.2791C121.327 28.8611 122.913 27.5812 127.191 27.5812H130.089V28.2442Z"
        fill="#2364E6"
      />
      <path
        d="M150.261 32.1602H150.213C149.808 29.8414 147.497 22.1158 144.849 13.4543H137.242L146.573 39.3362C145.613 42.1088 144.298 42.9951 141.69 42.9951C141.118 42.9951 140.335 42.9455 139.854 42.8923V48.4516C141.082 48.5899 142.549 48.6395 143.179 48.6395C149.337 48.6395 151.383 46.4839 153.836 39.2653L162.45 13.4436H155.383C152.318 24.0056 150.815 29.6323 150.261 32.1602Z"
        fill="#FE411A"
      />
      <path
        d="M203.013 37.2425V34.33H204.265C204.579 34.33 204.808 34.3569 204.95 34.4091C205.093 34.4614 205.206 34.5548 205.293 34.6879C205.379 34.8209 205.421 34.9745 205.421 35.1472C205.421 35.3657 205.355 35.5463 205.226 35.6888C205.096 35.8313 204.902 35.9216 204.643 35.958C204.771 36.0325 204.878 36.1133 204.961 36.2019C205.045 36.2906 205.158 36.449 205.299 36.6755L205.659 37.244H204.949L204.519 36.6105C204.367 36.3841 204.262 36.2415 204.206 36.1829C204.15 36.1243 204.09 36.0832 204.026 36.061C203.964 36.0388 203.863 36.0277 203.727 36.0277H203.607V37.244H203.013V37.2425ZM203.607 35.5621H204.047C204.332 35.5621 204.51 35.5494 204.582 35.5257C204.652 35.5019 204.708 35.4608 204.748 35.4022C204.788 35.3436 204.809 35.2707 204.809 35.1836C204.809 35.0854 204.782 35.0062 204.729 34.946C204.676 34.8859 204.601 34.8478 204.505 34.832C204.457 34.8257 204.313 34.8225 204.071 34.8225H203.607V35.5621V35.5621Z"
        fill="#FE411A"
      />
      <path
        d="M201.071 19.1062L201.012 19.0285C200.924 18.9055 200.833 18.7857 200.741 18.6772C199.667 17.327 198.63 16.6017 197.929 16.2245L187.123 1.65407C186.999 1.48408 185.874 -0.00372262 184.741 0.187312C184.386 0.247213 184.055 0.512719 183.784 0.95469C183.152 1.98434 182.763 4.21524 183.744 7.11476C185.027 10.9112 188.562 15.1172 192.406 17.4582C191.898 18.2029 191.351 19.4576 191.23 21.4731C191.215 21.6156 191.202 21.7581 191.197 21.9038C191.197 21.9653 191.197 22.0268 191.2 22.0883V22.234C191.19 22.3619 190.919 25.3958 193.779 27.2754C194.922 28.0266 196.305 28.3569 197.649 28.3876C199.748 28.4346 201.751 27.7514 202.598 26.7978C204.021 25.1951 203.463 22.3911 201.07 19.1062H201.071Z"
        fill="#FE411A"
      />
      <path
        d="M199.174 38.702C199.184 38.6907 199.194 38.6793 199.204 38.6664C199.209 38.6615 199.212 38.655 199.217 38.6502C199.559 38.1969 198.98 37.5007 198.365 36.5601C197.746 35.6147 195.11 32.0417 195.11 32.0417L191.139 26.3883L185.546 18.8165C183.083 15.3763 179.054 13.3235 174.767 13.3235C167.512 13.3235 161.611 19.0999 161.611 26.2005C161.611 33.3012 167.512 39.0776 174.767 39.0776H178.583C179.064 39.0776 179.471 38.6826 179.491 38.1969V33.3611C179.451 32.9078 179.058 32.5484 178.589 32.5435H174.767C171.194 32.5435 168.288 29.6991 168.288 26.2022C168.288 22.7052 171.194 19.8608 174.767 19.8608C176.879 19.8608 178.871 20.8823 180.095 22.5935L187.69 33.3676L189.129 35.422C190.831 37.7128 193.572 39.0808 196.461 39.0808H198.337C198.671 39.0808 198.97 38.9351 199.174 38.7052V38.702Z"
        fill="#FE411A"
      />
      <path
        d="M204.171 38.863C202.437 38.863 201.028 37.4835 201.028 35.787C201.028 34.0906 202.437 32.7111 204.171 32.7111C205.904 32.7111 207.313 34.0906 207.313 35.787C207.313 37.4835 205.904 38.863 204.171 38.863ZM204.171 33.3644C202.806 33.3644 201.695 34.4512 201.695 35.787C201.695 37.1229 202.806 38.2097 204.171 38.2097C205.535 38.2097 206.646 37.1229 206.646 35.787C206.646 34.4512 205.535 33.3644 204.171 33.3644Z"
        fill="#FE411A"
      />
    </svg>
  );
}
