import React from 'react';

export default function AppStore() {
  return (
    <svg
      width="164"
      height="38"
      viewBox="0 0 164 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.1953 19.7178C25.1515 15.0333 29.0309 12.7544 29.2085 12.6498C27.0146 9.4441 23.6071 9.00873 22.4104 8.97468C19.5525 8.67552 16.7774 10.687 15.3253 10.687C13.8733 10.687 11.5919 9.00387 9.17911 9.05251C6.0683 9.10359 3.15693 10.9034 1.56382 13.6981C-1.72941 19.3992 0.727138 27.7782 3.88173 32.3897C5.46024 34.6468 7.3063 37.1666 9.71906 37.0766C12.0808 36.9793 12.9612 35.571 15.8118 35.571C18.6623 35.571 19.465 37.0766 21.9288 37.0182C24.4632 36.9769 26.0587 34.7514 27.5813 32.4724C29.4079 29.8845 30.14 27.338 30.1692 27.2066C30.1084 27.1872 25.2464 25.3314 25.1977 19.7154L25.1953 19.7178Z"
        fill="#17212E"
      />
      <path
        d="M20.5423 5.94435C21.8119 4.35611 22.6851 2.19386 22.4394 0C20.6007 0.0802633 18.3047 1.26962 16.9815 2.82624C15.8092 4.19558 14.7609 6.44052 15.0309 8.54926C17.0983 8.70249 19.2167 7.50584 20.5423 5.94192V5.94435Z"
        fill="#17212E"
      />
      <path
        d="M59.9805 30.3347H56.5583L54.6831 24.4389H48.1672L46.3795 30.3347H43.0473L49.5073 10.2737H53.4913L59.978 30.3347H59.9805ZM54.1188 21.9678L52.4236 16.7288C52.2411 16.1961 51.9055 14.9338 51.4093 12.9516H51.3509C51.1515 13.8053 50.8329 15.0652 50.3975 16.7288L48.7314 21.9678H54.1188Z"
        fill="#17212E"
      />
      <path
        d="M76.5876 22.9212C76.5876 25.3826 75.9236 27.326 74.5931 28.7561C73.4038 30.0257 71.9226 30.6606 70.1592 30.6606C68.2523 30.6606 66.883 29.9747 66.0487 28.6078H65.9928V36.2279H62.775V20.6301C62.775 19.0832 62.7361 17.4974 62.6558 15.8678H65.482L65.662 18.1614H65.7228C66.793 16.4345 68.4226 15.571 70.6019 15.571C72.3044 15.571 73.7321 16.2472 74.8728 17.5947C76.0136 18.947 76.5827 20.7201 76.5827 22.9212H76.5876ZM73.3089 23.0404C73.3089 21.6321 72.9903 20.472 72.3579 19.5575C71.6623 18.6065 70.7283 18.1273 69.5609 18.1273C68.7655 18.1273 68.0432 18.3924 67.4035 18.9154C66.759 19.4456 66.3357 20.1339 66.1387 20.9876C66.039 21.3865 65.9904 21.71 65.9904 21.9678V24.3806C65.9904 25.4313 66.3114 26.3215 66.9535 27.0438C67.6005 27.7711 68.4396 28.131 69.4709 28.131C70.6821 28.131 71.6258 27.6689 72.2971 26.7349C72.9733 25.801 73.3114 24.5727 73.3114 23.0428"
        fill="#17212E"
      />
      <path
        d="M93.2242 22.9212C93.2242 25.3826 92.5602 27.326 91.2298 28.7561C90.0404 30.0257 88.5641 30.6606 86.7958 30.6606C84.8914 30.6606 83.5245 29.9747 82.6902 28.6078H82.6319V36.2279H79.4165V20.6301C79.4165 19.0832 79.3751 17.4974 79.2973 15.8678H82.1235L82.3035 18.1614H82.3643C83.4321 16.4345 85.0568 15.571 87.2434 15.571C88.9484 15.571 90.3712 16.2472 91.5144 17.5947C92.6551 18.947 93.2218 20.7201 93.2218 22.9212H93.2242ZM89.9504 23.0404C89.9504 21.6321 89.6318 20.472 88.997 19.5575C88.3014 18.6065 87.3698 18.1273 86.2 18.1273C85.4046 18.1273 84.6847 18.3924 84.0426 18.9154C83.398 19.4456 82.9772 20.1339 82.7802 20.9876C82.6781 21.3865 82.6294 21.71 82.6294 21.9678V24.3806C82.6294 25.4313 82.9505 26.3215 83.595 27.0438C84.2396 27.7711 85.0763 28.131 86.1124 28.131C87.3212 28.131 88.2649 27.6689 88.9386 26.7349C89.6099 25.801 89.9529 24.5727 89.9529 23.0428"
        fill="#17212E"
      />
      <path
        d="M111.825 24.7088C111.825 26.4162 111.234 27.8026 110.043 28.8752C108.737 30.0475 106.917 30.6312 104.58 30.6312C102.422 30.6312 100.688 30.2153 99.3773 29.3835L100.122 26.7056C101.535 27.5593 103.082 27.985 104.77 27.985C105.981 27.985 106.922 27.7126 107.601 27.1678C108.274 26.6205 108.61 25.8884 108.61 24.9763C108.61 24.164 108.333 23.4756 107.778 22.9187C107.224 22.3617 106.297 21.8461 105.005 21.3669C101.493 20.0535 99.7397 18.1369 99.7397 15.6123C99.7397 13.9632 100.353 12.6109 101.586 11.5602C102.814 10.5046 104.451 9.97681 106.496 9.97681C108.323 9.97681 109.841 10.2979 111.05 10.9302L110.247 13.5497C109.116 12.9368 107.837 12.6279 106.406 12.6279C105.275 12.6279 104.393 12.9052 103.76 13.4622C103.228 13.9584 102.958 14.564 102.958 15.2766C102.958 16.0695 103.262 16.7262 103.877 17.2394C104.41 17.7161 105.382 18.2318 106.793 18.7887C108.52 19.4844 109.787 20.2967 110.602 21.2283C111.414 22.1622 111.823 23.3224 111.823 24.7112"
        fill="#17212E"
      />
      <path
        d="M122.452 18.2778H118.911V25.3021C118.911 27.0873 119.536 27.98 120.788 27.98C121.36 27.98 121.837 27.9313 122.214 27.8316L122.301 30.2711C121.671 30.5095 120.835 30.6262 119.803 30.6262C118.534 30.6262 117.541 30.2395 116.826 29.4685C116.114 28.695 115.754 27.3914 115.754 25.5672V18.2754H113.64V15.8626H115.754V13.2164L118.908 12.2629V15.8626H122.45V18.2754L122.452 18.2778Z"
        fill="#17212E"
      />
      <path
        d="M138.434 22.982C138.434 25.2051 137.799 27.0292 136.53 28.4594C135.199 29.9285 133.431 30.6606 131.232 30.6606C129.034 30.6606 127.419 29.9576 126.156 28.547C124.896 27.1387 124.266 25.3632 124.266 23.2228C124.266 20.9779 124.913 19.144 126.215 17.7163C127.511 16.2837 129.262 15.571 131.466 15.571C133.669 15.571 135.297 16.2764 136.586 17.6846C137.819 19.0564 138.432 20.8198 138.432 22.982H138.434ZM135.097 23.0866C135.097 21.7538 134.81 20.6106 134.234 19.6547C133.562 18.4994 132.599 17.923 131.349 17.923C130.099 17.923 129.075 18.4994 128.401 19.6547C127.825 20.6106 127.54 21.7732 127.54 23.1499C127.54 24.5265 127.825 25.6259 128.401 26.5793C129.094 27.7322 130.067 28.311 131.32 28.311C132.572 28.311 133.509 27.7249 134.202 26.5501C134.798 25.5748 135.095 24.4219 135.095 23.0866"
        fill="#17212E"
      />
      <path
        d="M148.88 18.694C148.562 18.6381 148.226 18.6065 147.869 18.6065C146.74 18.6065 145.862 19.037 145.247 19.8882C144.714 20.6422 144.444 21.5957 144.444 22.7437V30.3322H141.231L141.258 20.4233C141.258 18.7548 141.216 17.2371 141.141 15.8678H143.941L144.06 18.6381H144.147C144.485 17.6871 145.023 16.9209 145.755 16.3469C146.468 15.8313 147.246 15.571 148.078 15.571C148.372 15.571 148.642 15.5905 148.88 15.627V18.694Z"
        fill="#17212E"
      />
      <path
        d="M163.252 22.415C163.252 22.9914 163.213 23.4754 163.136 23.8743H153.494C153.531 25.302 153.998 26.3965 154.89 27.1505C155.7 27.8242 156.756 28.1574 158.045 28.1574C159.468 28.1574 160.771 27.9312 161.944 27.474L162.447 29.7068C161.08 30.3027 159.463 30.6018 157.595 30.6018C155.355 30.6018 153.592 29.9402 152.312 28.6196C151.035 27.3013 150.393 25.5282 150.393 23.3076C150.393 21.087 150.989 19.309 152.181 17.8594C153.429 16.3125 155.117 15.5391 157.237 15.5391C159.358 15.5391 160.903 16.3101 161.975 17.8594C162.824 19.0901 163.25 20.6078 163.25 22.415H163.252ZM160.185 21.5807C160.207 20.6297 159.998 19.8052 159.563 19.112C159.006 18.2194 158.15 17.7718 156.999 17.7718C155.951 17.7718 155.095 18.2096 154.44 19.0828C153.905 19.7784 153.587 20.6102 153.492 21.5807H160.185Z"
        fill="#17212E"
      />
    </svg>
  );
}
