import React from 'react';

export default function FacebookIcon() {
  return (
    <svg width="16" height="27" viewBox="0 0 16 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.27853 26.5206V15.7496H0.847046V10.6787H5.27853V6.81938C5.27853 2.42617 7.88022 0 11.8652 0C13.1728 0.0181696 14.4774 0.132399 15.7685 0.341776V4.65431H13.568C13.1934 4.60417 12.8122 4.63941 12.4529 4.75742C12.0936 4.87543 11.7653 5.07318 11.4923 5.33604C11.2194 5.5989 11.0087 5.92012 10.876 6.27592C10.7433 6.63172 10.6919 7.01298 10.7256 7.39145V10.6787H15.5613L14.7879 15.7466H10.7256V26.5206"
        fill="#F42E3C"
      />
    </svg>
  );
}
