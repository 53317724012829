import {
  AppStore,
  BankayaLogo,
  Facebook,
  GooglePlay,
  Instagram,
  Mail,
  Youtube,
} from '../../components/icons';

import './style.sass';

const bkyHome = 'https://bankaya.com.mx';

const urls = {
  privacy: `${bkyHome}/aviso-de-privacidad`,
  terms: `${bkyHome}/terminos-y-condiciones`,

  facebook: 'https://www.facebook.com/Bankaya-113149793764606',
  youtube: 'https://www.youtube.com/channel/UCejTFxqV5OLwdUyVxI5AcKQ',
  instagram: 'https://www.instagram.com/bankayamx/',
  mail: 'mailto:hola@bankaya.com.mx',

  appStore: 'https://apps.apple.com/mx/app/bankaya/id1536961635',
  playStore: 'https://play.google.com/store/apps/details?id=mx.com.bankaya.products&hl=es_MX&gl=US',

  bkyHome: bkyHome,
  telephone: 'tel:55-1328-06707',
};

const Footer = () => {
  const version = process.env.REACT_APP_VERSION;
  return (
    <>
      <footer id="footer">
        <div className="footer-contact">
          <p>Centro de atención a clientes</p>
          <a href={urls.telephone} className="footer-phone-number">
            55 1328 0670
          </a>
          <div>
            <a title="facebook" href={urls.facebook} target="_blank" rel='noreferrer'>
              <Facebook />
            </a>
            <a title="youtube" href={urls.youtube} target="_blank" rel='noreferrer'>
              <Youtube />
            </a>
            <a title="instagram" href={urls.instagram} target="_blank" rel='noreferrer'>
              <Instagram />
            </a>
            <a title="email" href={urls.mail} target="_blank" rel='noreferrer'>
              <Mail />
            </a>
          </div>
        </div>
        <div className="footer-logo">
          <a title="bankaya-home" href={urls.bkyHome}>
            <BankayaLogo />
          </a>
        </div>
        <div className="footer-download-app">
          <p>Descarga la app</p>
          <div>
            <a title="app store" href={urls.appStore} target="_blank" rel='noreferrer'>
              <AppStore />
            </a>
            <a title="play store" href={urls.playStore} target="_blank" rel='noreferrer'>
              <GooglePlay />
            </a>
          </div>
        </div>
        <div className="footer-privacy">
          BKAYA S.A.P.I. de C.V. (Bankaya) es una empresa no regulada por las autoridades del sector
          financiero mexicano, enfocada al desarrollo tecnológico. Consubanco S.A. Institución de
          Banca Múltiple (Consubanco) es una empresa autorizada por la CNBV para constituirse y
          operar como Institución Financiera de Banca Múltiple, enfocada a ofrecer servicios
          bancarios y financieros. Las cuentas de ahorro y los depósitos son operados exclusivamente
          por Consubanco.
        </div>
        <div className="footer-terms">
          <a href={urls.privacy}>Aviso de privacidad</a>
          <div className="footer-line-separator" />
          <a href={urls.terms}>Terminos y condiciones</a>
        </div>
      </footer>
      <div className="footer-app-version">
        Bankaya - {new Date().getFullYear()} v{version}
      </div>
    </>
  );
};

export default Footer;
