import React from 'react';

export default function MailIcon() {
  return (
    <svg width="28" height="18" viewBox="0 0 28 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.50178 1.03693e-06C2.94984 -0.000490315 2.41175 0.173652 1.9638 0.497819L1.97425 0.508345L13.5322 11.4224C13.687 11.5388 13.8751 11.6017 14.0685 11.6017C14.2618 11.6017 14.4499 11.5388 14.6047 11.4224L26.1627 0.508345L26.1731 0.497819C25.7252 0.173652 25.1871 -0.000490315 24.6351 1.03693e-06H3.50178ZM1.11501 1.5146C0.945406 1.87089 0.857231 2.26078 0.85694 2.65576V15.2703C0.856154 15.6192 0.92395 15.9649 1.05644 16.2875C1.18892 16.6101 1.38349 16.9032 1.62897 17.15C1.87444 17.3967 2.16599 17.5923 2.48687 17.7255C2.80775 17.8587 3.15164 17.9268 3.49879 17.926H24.6366C24.9838 17.9268 25.3277 17.8587 25.6485 17.7255C25.9694 17.5923 26.261 17.3967 26.5064 17.15C26.7519 16.9032 26.9465 16.6101 27.079 16.2875C27.2115 15.9649 27.2793 15.6192 27.2785 15.2703V2.65576C27.2782 2.26078 27.19 1.87089 27.0204 1.5146L15.5132 12.3866C15.121 12.7543 14.6047 12.9587 14.0685 12.9587C13.5322 12.9587 13.0159 12.7543 12.6237 12.3866L1.11501 1.5146Z"
        fill="#F42E3C"
      />
    </svg>
  );
}
