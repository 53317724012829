import React from 'react';

export default function YoutubeIcon() {
  return (
    <svg width="35" height="25" viewBox="0 0 35 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M34.2682 3.90323C33.8665 2.36694 32.6793 1.15726 31.1739 0.745968C28.4455 0 17.5 0 17.5 0C17.5 0 6.55455 0 3.82614 0.745968C2.32074 1.15726 1.13352 2.36694 0.731818 3.90323C0 6.68952 0 12.5 0 12.5C0 12.5 0 18.3105 0.731818 21.0968C1.13352 22.6331 2.32074 23.8427 3.82614 24.254C6.55653 25 17.5 25 17.5 25C17.5 25 28.4455 25 31.1739 24.254C32.6793 23.8427 33.8665 22.6331 34.2682 21.0968C35 18.3125 35 12.5 35 12.5C35 12.5 35 6.68952 34.2682 3.90323Z"
        fill="#EE3124"
      />
      <path d="M14 18V7L23 12.5L14 18Z" fill="white" />
    </svg>
  );
}
